import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { useState } from 'react';
import ToggleSwitch, { ToggleState } from '@redoute/toggle-switch';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "toggleswitch"
    }}>{`ToggleSwitch`}</h1>
    <p>{`The ToggleSwitch is a styled component`}</p>
    <Playground __position={0} __code={'() => {\n  const [val1, setVal1] = useState(true)\n  const [val2, setVal2] = useState(false)\n  const [val3, setVal3] = useState(false)\n  return (\n    <>\n      <ToggleSwitch\n        checked={val1}\n        onChange={() => setVal1(!val1)}\n        htmlId=\"test\"\n      >\n        {checked => <ToggleState>{checked ? \'On\' : \'Off\'}</ToggleState>}\n      </ToggleSwitch>\n      <ToggleSwitch checked={val2} disabled onChange={() => setVal2(!val2)}>\n        {checked => <ToggleState>Disabled</ToggleState>}\n      </ToggleSwitch>\n      <ToggleSwitch checked={val3} focus onChange={() => setVal3(!val3)}>\n        {checked => <ToggleState>{checked ? \'On\' : \'Off\'}</ToggleState>}\n      </ToggleSwitch>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      useState,
      ToggleSwitch,
      ToggleState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const [val1, setVal1] = useState(true);
        const [val2, setVal2] = useState(false);
        const [val3, setVal3] = useState(false);
        return <>
                <ToggleSwitch checked={val1} onChange={() => setVal1(!val1)} htmlId='test' mdxType="ToggleSwitch">
                    {checked => <ToggleState mdxType="ToggleState">{checked ? 'On' : 'Off'}</ToggleState>}
                </ToggleSwitch>
                <ToggleSwitch checked={val2} disabled onChange={() => setVal2(!val2)} mdxType="ToggleSwitch">
                    {checked => <ToggleState mdxType="ToggleState">Disabled</ToggleState>}
                </ToggleSwitch>
                <ToggleSwitch checked={val3} focus onChange={() => setVal3(!val3)} mdxType="ToggleSwitch">
                    {checked => <ToggleState mdxType="ToggleState">{checked ? 'On' : 'Off'}</ToggleState>}
                </ToggleSwitch>
            </>;
      }}
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={ToggleSwitch} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      